
import { ArticleModel, AuthorModel, CategoryModel, SettingModel } from './database';
import { Article } from "../../blogUniqrate/article";
import { ArticlesList } from "../../blogUniqrate/articlesList";
import { Author } from "../../blogUniqrate/author";
import { Category } from "../../blogUniqrate/category";

const registeredBlogComponents: BlogComponents = {
  articleComponent: Article,
  articlesListComponent: ArticlesList,
  authorComponent: Author,
  categoryComponent: Category,
};

type ArticleComponentType = (props: { url: string; article: ArticleModel; setting: SettingModel }) => any;
type ArticlesListComponent = (props: {
  articles: ArticleModel[];
  locale: string;
  setting: SettingModel;
  totalArticles: number;
}) => any;
type AuthorComponent = (props: { url: string; author: AuthorModel }) => any;
type CategoryComponent = (props: { url: string; category: CategoryModel }) => any;

export interface BlogComponents {
  articleComponent: ArticleComponentType;
  articlesListComponent: ArticlesListComponent;
  authorComponent: AuthorComponent;
  categoryComponent: CategoryComponent;
}

export function updateBlogComponents(
  articleComponent: ArticleComponentType,
  articleListComponent: ArticlesListComponent,
  authorComponent: AuthorComponent,
  categoryComponent: CategoryComponent,
): BlogComponents {
  console.log('registerBlogComponents');
  registeredBlogComponents.articleComponent = articleComponent;
  registeredBlogComponents.articlesListComponent = articleListComponent;
  registeredBlogComponents.authorComponent = authorComponent;
  registeredBlogComponents.categoryComponent = categoryComponent;
  return registeredBlogComponents;
}

export function getBlogComponents(): BlogComponents {
  console.log('getBlogComponents', registeredBlogComponents);
  return registeredBlogComponents;
}