import { SettingModel } from "./service/common/database";


function formatUrl(url: string): string {
  if (url.endsWith('/')) {
    return url.substring(0, url.length - 1);
  }
  return url;
}

export function buildAuthorUrlFromPath(authorLocale: string, authorPath: string, setting: SettingModel): string {
  authorPath = authorPath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
  return formatUrl(
    authorLocale && authorLocale !== setting.defaultLocale
      ? `/${authorLocale}/blog/author${authorPath}`
      : `/blog/author${authorPath}`,
  );
}

export function buildCategoryUrlFromPath(categoryLocale: string, categoryPath: string, setting: SettingModel): string {
  categoryPath = categoryPath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
  return formatUrl(
    categoryLocale && categoryLocale !== setting.defaultLocale
      ? `/${categoryLocale}/blog/category${categoryPath}`
      : `/blog/category${categoryPath}`,
  );
}

export function buildDocumentationUrlFromPath(
  documentationLocale: string,
  documentationPath: string,
  setting: SettingModel,
): string {
  documentationPath = documentationPath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
  return formatUrl(
    documentationLocale && documentationLocale !== setting.defaultLocale
      ? `/${documentationLocale}/docs${documentationPath}`
      : `/docs${documentationPath}`,
  );
}

export function buildArticleUrlFromPath(articleLocale: string, articlePath: string, setting: SettingModel): string {
  articlePath = articlePath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
  return formatUrl(
    articleLocale && articleLocale !== setting.defaultLocale
      ? `/${articleLocale}/blog${articlePath}`
      : `/blog${articlePath}`,
  );
}

export function buildArticlesListUrl(locale: string, setting: SettingModel, page: number): string {
  if (page === 1) {
    return formatUrl(setting.defaultLocale !== locale ? `/${locale}/blog` : `/blog`);
  } else {
    return formatUrl(setting.defaultLocale !== locale ? `/${locale}/blog/page/${page}` : `/blog/page/${page}`);
  }
}

export function buildPageUrl(locale: string, pagePath: string, setting: SettingModel): string {
  pagePath = pagePath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
  return formatUrl(setting.defaultLocale !== locale ? `/${locale}${pagePath}` : pagePath);
}