import {
  ArticleModel,
  AuthorModel,
  CategoryModel,
  DocumentationMenuItemModel,
  DocumentationModel,
  PageModel,
  SectionModel,
  SettingModel,
} from './database';

export function mapToSettingModel(data: any): SettingModel {
  return {
    siteName: data.siteName,
    siteLogo: data.siteLogo,
    blogName: data.blogName,
    blogDescription: data.blogDescription,
    defaultLocale: data.defaultLocale,
    blogSectionsAfter: data.blogSectionsAfter,
    blogSectionsBefore: data.blogSectionsBefore,
    blogEnable: data.blogEnable,
    docEnable: data.docEnable,
    docSectionsAfter: data.docSectionsAfter,
    docSectionsBefore: data.docSectionsBefore,
    githubProfile: data.githubProfile,
  };
}

export function mapToArticleModel(data: any): ArticleModel {
  return {
    ref: data.ref,
    path: data.path,
    title: data.title,
    description: data.description,
    content: data.content,
    image: data.image,
    publishedAt: data.publishedAt,
    updatedAt: data.updatedAt,
    createdAt: data.createdAt,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
    category: mapToCategoryModel(data.category) || {},
    seo: data.seo || {},
    author: mapToAuthorModel(data.author) || {},
  };
}

export function mapToAuthorModel(data: any): AuthorModel {
  return {
    firstname: data.firstname,
    lastname: data.lastname,
    twitterAccount: data.twitterAccount,
    avatar: data.avatar,
    role: data.role,
    path: data.path,
    bio: data.bio,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
  };
}

export function mapToCategoryModel(data: any): CategoryModel {
  return {
    name: data.name,
    description: data.description,
    path: data.path,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
  };
}

export function mapToPageModel(data: any): PageModel {
  return {
    ref: data.ref,
    path: data.path,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
    seo: data.seo || {},
    publishedAt: data.publishedAt,
    updatedAt: data.updatedAt,
    sections: data.sections.map((section: any) => mapToSectionModel(section)),
  };
}

export function mapToDocumentationModel(data: any): DocumentationModel {
  return {
    ref: data.ref,
    path: data.path,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
    publishedAt: data.publishedAt,
    updatedAt: data.updatedAt,
    title: data.title,
    content: data.content,
    parentDocumentation: data.parentDocumentation,
    childDocumentations: (data.childDocumentations || []).map((documentation: any) =>
      mapToDocumentationModel(documentation),
    ),
  };
}

export function mapToDocumentationMenuItemModel(data: any): DocumentationMenuItemModel {
  return {
    path: data.path,
    locale: data.locale || data.coveredLocale, // Hygraph has a reserved "locale" field name
    title: data.title,
    parentDocumentation: data.parentDocumentation,
    childDocumentations: data.childDocumentations.map((documentation: any) =>
      mapToDocumentationMenuItemModel(documentation),
    ),
  };
}

export function mapToSectionModel(data: any): SectionModel {
  return {
    type: data.type,
    properties: data.properties,
  };
}