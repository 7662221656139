import React from 'react';
import { Link } from 'react-router-dom';

export default function FreeTrial() {
    return (
        <div className={'rmc-bg-secondary-2 pt-16 pb-16 px-40 max-lg:px-8'}>
            <div className={'max-w-[1240px] m-auto flex flex-col '}>
                <h2 className="tracking-normal text-center text-6xl font-extrabold line-height-[61px] rmc-text-base-1 mb-4 max-w-3xl m-auto">
                    Discover what your readers truly think
                </h2>
                <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center mb-10'}>
                    Combine traditional analytics with reader feedback. <br />Get what they love about your content. <br />Go beyond page views and visitor numbers.
                </p>
                <Link
                    to={process.env.REACT_APP_SIGNUP_URL as string}
                    className="text-center font-bold text-xl py-4 px-8 rmc-bg-primary-1 text-white hover:rmc-bg-primary-3 w-fit m-auto rounded-lg max-md:w-full"                    
                >
                    Start 14-day free trial
                </Link>
            </div>
        </div>
    );
}
