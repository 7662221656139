import { buildArticleUrlFromPath, buildAuthorUrlFromPath, buildCategoryUrlFromPath } from '../urlBuilder';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArticleModel, SettingModel } from "../service/common/database";
import databaseService from "../service/common/databaseService";

export function ArticlesList({
                               articles,
                               totalArticles,
                               locale,
                               setting,
                             }: {
  articles: ArticleModel[];
  locale: string;
  setting: SettingModel;
  totalArticles: number;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPages, setNumberPages] = useState(0);
  const [currentArticles, setArticles] = useState(articles);
  const location = useLocation();
  const {  pathname } = location;

  useEffect(() => {
    const numberPages = Math.ceil(totalArticles / 10);
    setNumberPages(numberPages);
  }, [totalArticles]);

  useEffect(() => {
    if (pathname.includes('/page')) {
      const match = pathname.match(/\/?blog\/page\/(\d+)\/?$/);
      const page = match ? parseInt(match[1], 10) : null
      setCurrentPage(page || 1);
    }
  }, [pathname]);

  const goToPage = async (page: number) => {
    setCurrentPage(page);
    const articles = await databaseService.getArticles(10, (page - 1) * 10);
    setNumberPages(Math.ceil(articles.total / 10));
    setArticles(articles.articles);
  };

  const goToPreviousPage = async () => {
    if (currentPage === 1) {
      return;
    } else {
      const page = currentPage;
      setCurrentPage(page - 1);
      const articles = await databaseService.getArticles(10, (page - 2) * 10);
      setNumberPages(Math.ceil(articles.total / 10));
      setArticles(articles.articles);
    }
  };

  const goToNextPage = async () => {
    if (currentPage === numberPages) {
      return;
    } else {
      const page = currentPage;
      setCurrentPage(page + 1);
      const articles = await databaseService.getArticles(10, page * 10);
      setNumberPages(Math.ceil(articles.total / 10));
      setArticles(articles.articles);
    }
  };

  const getPreviousPage = () => {
    if (currentPage === 2) {
      return '/blog';
    } else {
      return `/blog/page/${currentPage - 1}`;
    }
  };

  const getNextPage = () => {
    return `/blog/page/${currentPage + 1}`;
  };

  return (
    <div className="pt-14 pb-20  rmc-bg-primary-4">
      <div className="mx-auto max-w-5xl px-8">
        <div className="mx-0 sm:mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight rmc-text-base-1 sm:text-4xl">
            {setting.blogName}
          </h2>
          <p className="mt-2 text-lg leading-8 rmc-text-base-1">{setting.blogDescription}</p>
        </div>
        <ul className="grid grid-cols-1 gap-x-8 gap-y-16 pt-14 mx-0 max-w-none sm:grid-cols-2 xl:grid-cols-3">
          {currentArticles
            .filter((a) => a.locale === locale)
            .map((article) => (
              <li
                key={article.path}
                className="flex gap-6 overflow-hidden flex-col items-start justify-between rmc-bg-secondary-2 shadow rounded-3xl p-6 card-shadow"
              >
                <div className="relative w-full">
                  <Link
                    to={buildArticleUrlFromPath(
                      article.locale as string,
                      article.path as string,
                      setting,
                    )}
                  >
                    <img
                      loading="lazy"
                      src={article.image}
                      alt={article.title + ' image.'}
                      className="aspect-[16/9] w-full bg-gray-100 object-cover lg:aspect-[3/2] rounded-lg"
                    />
                  </Link>
                </div>
                <div className="w-full">
                  <div className="group relative">
                    <h3 className="text-[25px] font-semibold leading-6 rmc-text-base-1 group-hover:text-gray-600">
                      <Link
                        to={buildArticleUrlFromPath(
                          article.locale as string,
                          article.path as string,
                          setting,
                        )}
                      >
                        {article.title}
                      </Link>
                    </h3>
                    <p className="mt-4 line-clamp-3 text-base leading-6 rmc-text-base-1">
                      {article.description}
                    </p>
                  </div>
                  <section className="relative mt-6 flex flex-col items-start gap-4">
                    <Link
                      to={buildCategoryUrlFromPath(
                        article.category.locale as string,
                        article.category.path as string,
                        setting,
                      )}
                      className="relative z-10 rounded-full rmc-bg-base-1 px-3 py-1.5 font-medium text-sm text-white hover:rmc-bg-neutral-2"
                    >
                      {article.category.name}
                    </Link>
                    <div className="flex-1 flex gap-x-2 items-center">
                      <img
                        loading="lazy"
                        src={article.author.avatar || '/default-avatar.png'}
                        alt="user avatar"
                        className="h-6 w-6 rounded-full rmc-bg-secondary-2"
                      />

                      <div className="text-sm leading-5 flex-1">
                        <p className="font-semibold rmc-text-base-1">
                          <Link
                            to={buildAuthorUrlFromPath(
                              article.author.locale as string,
                              article.author.path as string,
                              setting,
                            )}
                          >
                            <span className="absolute inset-0" />
                            {article.author.firstname} {article.author.lastname}
                          </Link>
                        </p>
                        <p className="rmc-text-base-2">{article.author.role}</p>
                      </div>
                    </div>
                  </section>
                </div>
              </li>
            ))}
        </ul>
        <div className={'flex justify-center mt-10'}>
          <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
            <Link
              to={getPreviousPage()}
              className={`relative bg-white inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${currentPage === 1 ? 'pointer-events-none' : ''}`}
              onClick={() => goToPreviousPage()}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </Link>
            {Array.from({ length: numberPages }, (_, i) => i + 1).map((number) => {
              const buttonColor = number === currentPage ? 'primary' : 'inherit';
              return (
                <Link
                  to={`${number === 1 ? '/blog' : `/blog/page/${number}`}`}
                  key={number}
                  className={`${
                    currentPage === number
                      ? 'z-10 rmc-bg-primary-1 px-4 py-2 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }`}
                  onClick={() => goToPage(number)}
                  color={buttonColor}
                >
                  {number}
                </Link>
              );
            })}
            <Link
              to={getNextPage()}
              className={`bg-white relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${currentPage === numberPages ? 'pointer-events-none' : ''}`}
              onClick={() => goToNextPage()}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}