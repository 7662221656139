
import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildArticlesListUrl } from '../urlBuilder';
import { onlyUnique } from '../onlyUnique';
import { useLocation } from 'react-router-dom';
import { ArticleModel, SettingModel } from "../service/common/database";
import { getBlogComponents } from "../service/common/registerBlogComponent";
import { seoService } from "../service/common/seoService";

export function ArticleListRenderer({
    articles,
    locale,
    setting,
    totalArticles,
    currentPage = 1
                                    }: {
  articles: ArticleModel[];
  locale: string;
  setting: SettingModel;
  totalArticles: number;
  currentPage: number;
}) {
  const rootUrl = process.env.REACT_APP_ROOT_URL as string;
  const pageUrl = rootUrl + buildArticlesListUrl(locale, setting, currentPage);
  const alternativesLocalesPageAvailable = articles.map((a) => a.locale).filter(onlyUnique);
  const ArticlesList = getBlogComponents().articlesListComponent;
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: locale,
        }}
      >
        {seoService.basicMetaTags({
          pageUrl: pageUrl,
          description: setting.blogDescription,
          title: setting.blogName,
          image: seoService.defaultImage(rootUrl),
        })}
        {seoService.opengraphLocales(locale as string, alternativesLocalesPageAvailable as string[])}
        {seoService.opengraphWebsiteMetaTags()}
        {seoService.twitterMetaTags(
          {
            pageUrl: pageUrl,
            description: setting.blogDescription,
            title: setting.blogName,
            image: seoService.defaultImage(rootUrl),
          },
          {
            siteAccount: seoService.defaultTwitterSiteAccount(),
            creatorAccount: seoService.defaultTwitterCreatorAccount(),
          },
        )}
      </Helmet>
      <div>
        <SectionsRenderer sections={setting.blogSectionsBefore} locale={locale} />
        <ArticlesList articles={articles} locale={locale} setting={setting} totalArticles={totalArticles} />
        <SectionsRenderer sections={setting.blogSectionsAfter} locale={locale} />
      </div>
    </div>
  );
}